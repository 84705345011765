import React from "react";
import {ThemeProvider} from 'styled-components';

import Layout from "../components/layout" 
import SEO from "../components/seo" 
import HeroTop from "../components/HeroTop" 

import Navigation from 'common/sections/Navigation'; 
import Banner from 'common/sections/Banner-default'; 
import About from 'common/sections/About';
import Features from 'common/sections/Features';
import Faq from 'common/sections/Faq';
import Integrations from 'common/sections/Integrations';
import AppScreens from 'common/sections/AppScreens';
import Counters from 'common/sections/Counters';
import Pricing from 'common/sections/Pricing';
import Testimonial from 'common/sections/Testimonial';
import News from 'common/sections/News';
import Getapp from 'common/sections/Getapp';
import Contact from 'common/sections/Contact';
import Footer from 'common/sections/Footer';
import Hello from 'common/sections/Hello';

import { GlobalStyle } from 'common/sections/app.style';
import theme from 'common/theme/app/themeStyles';
import WhatWeDo from '../common/sections/WhatWeDo/index';
import ParticleComponent from "common/reusecore/Particle";

const IndexPage = () => (
  <ThemeProvider theme={theme}> 
    
    <GlobalStyle />
     <Layout>
       
      <SEO title="Kodo Develop" />
      
      <HeroTop/>
      <Hello/>
      <WhatWeDo/>
    
      {/* <Navigation /> */}
      {/* <Banner />
      <About />
      <Integrations />
      <Features />
      <AppScreens />
      <Counters /> 
      <Pricing />
      <Testimonial />
      <Faq />
      <Getapp />
      <News />
      <Contact /> */}
      <Footer />
      {/* <ParticleComponent/> */}
    </Layout>
  </ThemeProvider>
)

export default IndexPage
